export const config = {
	XhrHttpModule: {
		origin: "",
		timeout: 30000,
		compress: false
	},
	ThunderstormModule: {
		appName: 'IR Support Portal - DEV'
	},
	ActivationModule: {
		userOnlyEnabled: true
	},
	EnvironmentModule: {
		envName: "dev",
		kasperoFeUrl: "https://ir-q-kaspero-dev.firebaseapp.com",
		apiServerUrl: "https://elliq-api-server-dev.s.elliq.co:443/api/"
	},
	ExampleModule: {
		remoteUrl: "/v1/sample/endpoint-example"
	},
	PackageManagerModule: {
		websiteUrl: "https://ir-q-package-manager-dev.firebaseapp.com",
		functionUrl: "https://us-central1-ir-q-package-manager-dev.cloudfunctions.net/api"
	},
	ContactsModule: {
		websiteUrl: "https://ir-q-messaging-dev.firebaseapp.com",
		defaultAreaCode: "+972",
	},
	CareCenterModule: {
		websiteUrl: "https://ir-q-care-center-dev.firebaseapp.com",
		commandsUrl: "https://ir-commands-dev.web.app"
	},
	FirebaseModule: {
		local: {
			apiKey: "AIzaSyCeGOack6XgSyb-xHyxUuFSS5AI4ovLhhQ",
			authDomain: "ir-q-support-dev.firebaseapp.com",
			databaseURL: "https://ir-q-support-dev.firebaseio.com",
			projectId: "ir-q-support-dev",
			storageBucket: "ir-q-support-dev.appspot.com",
			messagingSenderId: "731367534",
			appId: "1:731367534:web:bfb26c2cd51bab8f6d3e7f"
		},
		pm: {
			apiKey: "AIzaSyAaHe2bDwXEdjBtv9ncXoPKhrBu-t1IfmI",
			authDomain: "ir-q-package-manager-dev.firebaseapp.com",
			databaseURL: "https://ir-q-package-manager-dev.firebaseio.com",
			projectId: "ir-q-package-manager-dev",
			storageBucket: "ir-q-package-manager-dev.appspot.com",
			messagingSenderId: "234123179335",
			appId: "1:234123179335:web:650fcfe1bdde8767ca4862",
			measurementId: "G-WEJCNHJEPQ"
		},
		ks: {
			apiKey: "AIzaSyDZHdnc1mUdQTA4FjKrvmlszHfBy0JK6hk",
			authDomain: "elliq-env-map.firebaseapp.com",
			databaseURL: "https://elliq-env-map.firebaseio.com",
			projectId: "elliq-env-map",
			storageBucket: "elliq-env-map.appspot.com",
			messagingSenderId: "1067544170250",
			appId: "1:1067544170250:web:2acdc4358c16ee9b4034f3"
		},
		sd: {
			apiKey: "AIzaSyA7YqQxjZO8M_ayGUwkY3YrJPH4gU9jg5w",
			authDomain: "ir-q-state-dashboard-staging.firebaseapp.com",
			databaseURL: "https://ir-q-state-dashboard-staging.firebaseio.com",
			projectId: "ir-q-state-dashboard-staging",
			storageBucket: "ir-q-state-dashboard-staging.appspot.com",
			messagingSenderId: "868100673306",
			appId: "1:868100673306:web:0a82b76ed1eed42bcde6a3"
		}
	},
	LocaleModule: {
		defaultLocale: "en",
		locales: [
			{
				locale: "en",
				label: "Language_English",
				icon: "languages/en",
				texts: require(`./res/localization/en`)
			},
			{
				locale: "nl",
				label: "Language_Dutch",
				icon: "languages/nl",
				texts: require(`./res/localization/nl`)
			}
		]
	},
	installBlockerConditions: [
		{
			currentVersionLowerThan: "3.4.3",
			packageVersionIsEqualOrBiggerThan: "3.4.3",
			mustInstallFirst: "3.4.3"
		}
	],
	BigQueryModule: {
		dataSet: "ir-datastore-test"
	}
};
